<template>
  <div>
    <div class="products">
      <div class="section_container">
        <div class="container">
          <div class="row">
            <div class="col">
              <div
                class="my-5 py-5"
                v-if="loading"
              >
                <loader />
              </div>
              <div
                class="text-center my-5 pt-5"
                v-if="!productsAvailable"
              >
                Products are not available
              </div>
              <isotope
                class="products_container grid"
                ref="products"
                v-images-loaded:on.progress="relayout"
                :list="products"
                :options="{}"
              >
                <div
                  class="product grid-item"
                  v-for="(product, p) of products"
                  :key="p"
                  :class="{ 'sale': product.discount_price }"
                >
                  <div class="flex flex-column">
                    <router-link
                      class="product_image"
                      :to="`/product/${getProductSlug(product.canonical)}`"
                    >
                      <img
                        :src="product.images[0].thumbnail_url"
                        :alt="product.images[0].name"
                      />
                      <div
                        class="product_tag"
                        v-if="product.discount_price"
                      >
                        {{ product.discount_price ? 'sale' : '' }}
                      </div>
                    </router-link>
                    <div class="product_content text-center">
                      <router-link
                        class="product_title"
                        :to="`/product/${getProductSlug(product.canonical)}`"
                      >
                        {{ product.name }}
                      </router-link>
                      <div
                        class="product_price"
                        v-if="product.quantity"
                      >
                        ${{ product.discount_price ? product.discount_price : product.regular_price }}
                        <strike v-if="product.discount_price">${{ product.regular_price }}</strike>
                      </div>
                      <div
                        class="product_price soldout"
                        v-else
                      >
                        SOLD OUT
                      </div>
                      <div
                        class="product_button ml-auto mr-auto trans_200"
                        :class="{ 'disabled': isLocked(product) }"
                        :title="getButtonTooltip(product)"
                        @click="addToCart(product)"
                        v-if="product.quantity"
                      >
                        add to cart
                      </div>
                      <div class="product_button-invisible" v-else />
                    </div>
                  </div>
                </div>
              </isotope>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
import Isotope from 'vueisotope';
import imagesLoaded from 'vue-images-loaded';
import Loader from '@/components/Loader';

export default {
  name: 'categories',

  components: {
    Isotope,
    Loader,
  },

  directives: {
    imagesLoaded,
  },

  data: () => ({
    name: null,
    heroImage: null,
    products: [],
    loading: false,
    productsAvailable: true,
  }),

  watch: {
    $route: 'fetch',
  },

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      this.products = [];
      this.loading = true;
      try {
        const { data } = await this.$api.category(this.$route.params.slug);
        this.name = data.name;
        this.heroImage = data.hero_url;
        this.products = data.products.filter(r => r.status === 'active');
      } catch (error) {
        /* eslint-disable no-console */
        console.debug(error);
      } finally {
        this.loading = false;
        if (!this.products.length) this.productsAvailable = false;
      }
    },
    relayout() {
      this.$refs.products.layout('masonry');
    },
    addToCart(item) {
      if (!this.isLocked(item)) {
        this.$store.commit('ADD_TO_CART', item);
      }
    },
    getProductSlug(canonical) {
      const parts = canonical.split('/');
      return parts[parts.length - 1];
    },
    getButtonTooltip: ({ lock_by }) => lock_by
      ? `Please add to cart item "${lock_by}" to purchase this product`
      : false,
    isLocked({ lock_by }) {
      return !!lock_by && !this.$store.state.cart.find(item => item.sku === lock_by);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/views/categories.scss';
</style>
